import request from '@/utils/request'

// 创建产品
export function createProduct (params) {
  return request.post('/api/createProduct', params)
}

// 修改产品
export function editProduct (params) {
  return request.post('/api/editProduct', params)
}
 
// 产品列表
export function listProduct (params) {
  return request.post('/api/listProduct', params)
}
 
// 删除禁用产品
export function statusProduct (params) {
  return request.post('/api/statusProduct', params)
}

// 产品详情
export function infoProduct (params) {
  return request.post('/api/infoProduct', params)
}

// 修改助梦金配置
export function setZMJAppConfig (params) {
  return request.post('/api/setZMJAppConfig', params)
}

// 获取助梦金配置
export function getZMJAppConfig (params) {
  return request.post('/api/getZMJAppConfig', params)
}
 