<template>
  <div>
    <el-dialog
      :title="title"
      width="770px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form
        ref="productForm"
        :model="productForm"
        size="small"
        :rules="rules"
      >
        <el-form-item
          label="商家名称"
          label-width="100px"
          prop="business_id"
          style="margin-bottom: 15px"
          v-if="!business_id"
        >
          <el-select
            style="width: 100%"
            v-model="productForm.business_id"
            :remote-method="businessRemoteMethod"
            :loading="businessLoading"
            placeholder="请输入关键词"
            clearable
            filterable
            remote
            @clear="onBusinessClear"
          >
            <el-option
              v-for="item in businessList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="商家名称"
          label-width="100px"
          style="margin-bottom: 15px"
          v-else
        >
          <span>{{ business_name }}</span>
        </el-form-item>
        <el-form-item
          label="产品名称"
          label-width="100px"
          prop="product_name"
          style="margin-bottom: 15px"
        >
          <el-input
            v-model="productForm.product_name"
            placeholder="请输入"
            maxlength="50"
          />
        </el-form-item>
        <el-form-item
          label="产品价格"
          label-width="100px"
          prop="price"
          style="margin-bottom: 15px"
        >
          <el-input v-model="productForm.price" placeholder="请输入" />
        </el-form-item>
        <el-form-item
          label="产品说明"
          label-width="100px"
          prop="description"
          style="margin-bottom: 0px"
        >
          <el-input
            v-model="productForm.description"
            type="textarea"
            :rows="3"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm('productForm')" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { createProduct, editProduct, infoProduct } from "@/api/product.js";
import { selectBusiness } from "@/api/selectfun.js";
export default {
  name: "produvtModal",
  data() {
    return {
      dialogFormVisible: false,
      title: "新增产品",
      product_id: "",
      businessList: [],
      businessLoading: false,
      productForm: {
        business_id: "",
        product_name: "",
        price: "",
        description: "",
      },
      rules: {
        business_id: [
          { required: true, message: "请选择商家", trigger: "change" },
        ],
        product_name: [
          { required: true, message: "请输入产品名称", trigger: "blur" },
        ],
        price: [{ required: true, message: "请输入产品价格", trigger: "blur" }],
      },
      business_id: "",
      business_name: "",
    };
  },
  methods: {
    async isShow(type, val, business_id, business_name) {
      this.dialogFormVisible = true;
      this.business_id = business_id;
      this.business_name = business_name;
      if (business_id) {
        this.productForm.business_id = business_id;
      }
      if (type == "edit") {
        this.title = "修改产品";
        this.product_id = val.product_id;
        this.getInfoProduct(val.product_id);
      } else if (type == "add") {
        this.product_id = "";
        this.title = "新增产品";
        this.businessList = [];
      }
    },
    async getInfoProduct(id) {
      const parme = {
        product_id: id,
      };
      const { code, data } = await infoProduct(parme);
      if (code == 200) {
        this.productForm = {
          product_id: data.product_id,
          business_id: data.business_id,
          product_name: data.product_name,
          price: data.price,
          description: data.description,
        };
        this.getSelectBusiness(data.business_name);
      }
    },
    onBusinessClear() {
      this.productForm.business_id = "";
      this.businessList = [];
    },
    businessRemoteMethod(query) {
      if (query != "") {
        this.businessLoading = true;
        setTimeout(() => {
          this.businessLoading = false;
          this.getSelectBusiness(query);
        }, 200);
      } else {
        this.businessList = [];
      }
    },
    async getSelectBusiness(query) {
      const param = {
        business_name: query,
      };
      const { code, data } = await selectBusiness(param);
      if (code == 200) {
        this.businessList = data.map((item) => {
          item.value = item.business_id;
          item.label = item.full_name;
          return item;
        });
      }
    },
    confirm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res;
          this.product_id
            ? (res = await editProduct(this.productForm))
            : (res = await createProduct(this.productForm));
          if (res.code == 200) {
            this.close();
            this.$message.success("操作成功");
            this.$parent.initData();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.dialogFormVisible = false;
      this.productForm = {};
      this.$refs.productForm.resetFields();
    },
    handleClose(done) {
      done();
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .customW {
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .classification {
    margin: 10px 40px;
    .content-form {
      margin: 0px 10px;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>